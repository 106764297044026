import React, { useState, useId } from 'react';

// import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import s from './Accordion.module.scss';
import classNames from 'classnames';
import ButtonPrimaryEllipse from '../ButtonPrimaryEllipse';
import { trackAccordion } from '../../utils/dataLayer';

const Accordion = ({ title, text, richText }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const id = useId();
  const headerId = useId();

  const classes = classNames(s.Root, {
    [s.Expanded]: isExpanded,
  });

  const handleClick = (title) => {
    if (!isExpanded) {
      trackAccordion(title);
    }

    setIsExpanded(!isExpanded);
  };

  const icon = isExpanded ? 'icon-minus' : 'icon-plus';

  return (
    <div
      className={classes}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div className={s.ButtonContainer}>
        <h3 className={s.Title}>{title}</h3>
        <ButtonPrimaryEllipse
          onClick={() => handleClick(title)}
          modifiers={['AccordionIcon']}
          aria-expanded={isExpanded}
          aria-controls={id}
          id={headerId + '-header'}
          icon={icon}
          // onColor={'white'}
          active={isHovered}
        />
      </div>
      <div
        className={s.Content}
        aria-labelledby={headerId + '-header'}
        aria-hidden={!isExpanded}
        id={id}>
        <div className={s.Inner}>
          {richText ? (
            <div
              className={s.RichText}
              dangerouslySetInnerHTML={{ __html: richText }}
            />
          ) : (
            <div className={s.Text}>{text}</div>
          )}
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

Accordion.defaultProps = {
  title: '',
  text: '',
};

export default Accordion;
