import React from 'react';

// import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import s from './AccordionList.module.scss';
import Accordion from '../Accordion';
import classNames from 'classnames';

const AccordionList = ({ title, items, fromPage }) => {
  if (!items.length) {
    return;
  }

  const rootClasses = classNames(s.Root, 'grid', {
    [s.ReportPage]: fromPage === 'reportPage',
    [s.OfferPage]: fromPage === 'offerPage',
  });

  return (
    <div className={rootClasses}>
      <div className={s.Container}>
        {title && <h2 className={s.Title}>{title}</h2>}
        {items.map((item, index) => (
          <Accordion key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

AccordionList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
};

AccordionList.defaultProps = {
  title: '',
  items: [],
};

export default AccordionList;
